<template>
  <div class="border">
    <div class="chart-bar" ref="bar"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myChart: null,
      HumDatas:[],
      HumTimes:[],
    };
  },
  props: {
    HumData: {
      type: Array,
      default: null,
    },
    HumTime: {
      type: Array,
      default: null,
    },
  },
  created() {
    this.initTem();
  },
  watch: {
    HumData(newval, oldval) {
      console.log(newval);
      
     this.initTem(),
      this.myChart.resize();
      this.showChartData();
    },
  },
  mounted() {
    this.showChartData();
    this.startInterval();
  },
  methods: {
    startInterval: function() {
      if (this.timeId) {
        clearInterval(this.timeId);
      }
      this.timeId = setInterval(() => {
        this.initTem();
        this.showChartData();
      }, 1000);
    },
    initChart() {
      if (this.myChart === null) {
        this.myChart = this.$echarts.init(this.$refs.bar, "chalk");
      }
      return this.myChart;
    },
    initTem() {
      this.HumDatas = this.HumData;
      this.HumTimes = this. HumTime
    },
    //显示图表数据
    showChartData() {
      //this.chartInstance=this.$echarts.init(this.$refs.curve,'chalk');
      let result = this.initChart();
      window.addEventListener("resize", function() {
        result.resize();
      });
      let initOption = {
        color: ["#8dc675", "#f9c757"],
        /*title:{
                    text:'湿度'
                },*/
        tooltip: {
          trigger: "axis",
        },

        legend: {
          right: 10,
          top: 0,
          itemWidth: 15,
          itemHeight: 6,
          itemGap: 10,
          data: ["湿度"],
        },
        grid: {
          top: "20%",
          left: "3%",
          right: "10%",
          bottom: "1%",
          containLabel: true,
        },
        animation: true,
        animationDuration: function(idx) {
          // 越往后的数据延迟越大
          return idx * 300;
        },
        animationEasing: "cubicInOut",
        xAxis: {
          type: "category",
          name: "分",
          data: this.HumTimes,
        },
        yAxis: {
          type: "value",
          name: "单位：%rh",
        },
        series: [
          {
            name: "湿度",
            type: "line",
            data: this.HumData,
          },
        ],
      };
      result.setOption(initOption);
    },
  },
};
</script>
<style>
.chart-bar {
  width: 100%;
  height: 100%;
}
</style>
